import { Observable, firstValueFrom, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { translate } from '@jsverse/transloco';
import { MemoizeObservable, lazyShareReplay } from '@mhp/common';
import { I18nService } from '@mhp/ui-shared-services';

import { ConfigurationSessionInfoService } from '../configuration/session-info/configuration-session-info.service';
import { AmlProductDataService } from '../product-data/aml-product-data-service';

@Injectable({
    providedIn: 'root'
})
export class LabelHelperService {
    constructor(
        private readonly i18nService: I18nService,
        private readonly configurationSessionInfoService: ConfigurationSessionInfoService,
        private readonly productDataService: AmlProductDataService
    ) {}

    /**
     * Gets the combination of model and product-name for the currently active model and
     * product id.
     */
    @MemoizeObservable()
    getActiveModelAndProductName$() {
        return this.configurationSessionInfoService
            .getActiveConfigurationSessionInfo$()
            .pipe(
                map((sessionInfo): string | undefined => {
                    if (!sessionInfo) {
                        return undefined;
                    }
                    return this.getProductName(
                        sessionInfo.modelId,
                        sessionInfo.productId
                    );
                }),
                lazyShareReplay()
            );
    }

    /**
     * Gets model-name for the currently active model.
     */
    @MemoizeObservable()
    getActiveModelName$() {
        return this.configurationSessionInfoService
            .getActiveConfigurationSessionInfo$()
            .pipe(
                map((sessionInfo): string | undefined => {
                    if (!sessionInfo) {
                        return undefined;
                    }
                    return this.getModelName(
                        sessionInfo.modelId,
                        sessionInfo.productId
                    );
                }),
                lazyShareReplay()
            );
    }

    /**
     * Gets product-name for the currently active derivate.
     */
    @MemoizeObservable()
    getActiveDerivateName$() {
        return this.configurationSessionInfoService
            .getActiveConfigurationSessionInfo$()
            .pipe(
                map((sessionInfo): string | undefined => {
                    if (!sessionInfo) {
                        return undefined;
                    }
                    return this.getDerivateName(
                        sessionInfo.modelId,
                        sessionInfo.productId
                    );
                }),
                lazyShareReplay()
            );
    }

    /**
     * Gets the description for currently active product.
     */
    @MemoizeObservable()
    getActiveProductDescription$() {
        return this.configurationSessionInfoService
            .getActiveConfigurationSessionInfo$()
            .pipe(
                switchMap((sessionInfo): Observable<string | undefined> => {
                    if (!sessionInfo) {
                        return of(undefined);
                    }
                    return this.i18nService.selectTranslateWithFallback$(
                        `DERIVATIVE_SELECTION.${sessionInfo.modelId}.${sessionInfo.productId}.DESCRIPTION`,
                        translate(
                            `DERIVATIVE_SELECTION.${sessionInfo.modelId}.DESCRIPTION`
                        )
                    );
                }),
                lazyShareReplay()
            );
    }

    /**
     * Get the image-src + additional rendering hints for the currently active product.
     */
    @MemoizeObservable()
    getActiveProductLogoInfo$(): Observable<
        | {
              imageSrc: string;
              logoType: '1L' | '2L';
          }
        | undefined
    > {
        return this.configurationSessionInfoService
            .getActiveConfigurationSessionInfo$()
            .pipe(
                switchMap(async (sessionInfo) => {
                    if (!sessionInfo) {
                        return undefined;
                    }
                    try {
                        const derivativeInfo = await firstValueFrom(
                            this.productDataService.getProductMeta$(
                                sessionInfo.productId
                            )
                        );
                        let logoType: '1L' | '2L' = '1L';
                        let imageSrc = `assets/images/model-select/${sessionInfo.modelId.toLowerCase()}.svg`;
                        if (derivativeInfo?.useDerivativeSpecificLogo) {
                            logoType =
                                derivativeInfo.useDerivativeSpecificLogo ===
                                '2L'
                                    ? '2L'
                                    : '1L';
                            imageSrc = `assets/images/model-select/${sessionInfo.productId.toLowerCase()}.svg`;
                        }
                        return {
                            imageSrc,
                            logoType
                        };
                    } catch {
                        return undefined;
                    }
                })
            );
    }

    /**
     * Returns the model-name.
     * @param modelId
     */
    getModelName(modelId: string, productId?: string) {
        const modelBaseName = this.i18nService.translateWithFallback(
            `DERIVATIVE_SELECTION.${modelId}.MODEL_NAME`,
            modelId
        );
        if (!productId) {
            return modelBaseName;
        }
        return this.i18nService.translateWithFallback(
            `DERIVATIVE_SELECTION.${modelId}.${productId}.MODEL_NAME`,
            modelBaseName
        );
    }

    /**
     * Returns the derivate-name.
     * @param modelId
     * @param productId
     * @param language The language to use for the derivative-name. Defaults to the currently active language.
     */
    getDerivateName(modelId: string, productId: string, language?: string) {
        const derivativeRaw = this.i18nService.translateWithFallback(
            `DERIVATIVE_SELECTION.${modelId}.${productId}.DERIVATIVE_NAME`,
            productId
        );

        const labelParams = {
            derivatename: derivativeRaw
        };

        return this.i18nService.translateWithFallback(
            `MODEL_LABEL.${productId}.LABEL_SHORT`,
            translate<string>(
                `MODEL_LABEL.DEFAULT.LABEL_SHORT`,
                labelParams,
                language
            ),
            labelParams,
            language
        );
    }

    /**
     * Returns the product-name which is a combination of model- + derivate-name.
     * Note, that in case model- and derivate-name are equal, name will be reduced
     * to only return one of them.
     * @param modelId
     * @param productId
     */
    getProductName(modelId: string, productId: string): string {
        const modelName = this.getModelName(modelId, productId);
        const derivativeName = this.getDerivateName(modelId, productId);

        if (modelName === derivativeName) {
            return modelName;
        }

        const labelParams = {
            modelname: modelName,
            derivatename: derivativeName
        };

        const productLabel = this.i18nService.translateWithFallback(
            `MODEL_LABEL.${productId}.LABEL_FULL`,
            translate(`MODEL_LABEL.DEFAULT.LABEL_FULL`, labelParams),
            labelParams
        );

        return productLabel;
    }
}
